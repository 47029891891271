/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import styled from 'styled-components'
import Nav from '../components/Nav/nav'
import SEO from '../components/SEO'
import Layout from '../hoc/Layout/layout'

const Section = styled.section`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Page404 = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <Section>
        <h1>404</h1>
        <h2>Ta strona nie istnieje...</h2>
      </Section>
    </Layout>
  )
}

export default Page404
